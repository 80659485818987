exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-page-js": () => import("./../../../src/pages/NotFoundPage.js" /* webpackChunkName: "component---src-pages-not-found-page-js" */),
  "component---src-pages-poems-js": () => import("./../../../src/pages/poems.js" /* webpackChunkName: "component---src-pages-poems-js" */),
  "component---src-pages-published-works-js": () => import("./../../../src/pages/publishedWorks.js" /* webpackChunkName: "component---src-pages-published-works-js" */),
  "component---src-templates-poetry-details-js": () => import("./../../../src/templates/poetry-details.js" /* webpackChunkName: "component---src-templates-poetry-details-js" */)
}

